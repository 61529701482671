.card {
  background-color: white;
  margin-top: 0%;
  margin-right: 0%;
  /* padding-left: 3rem; */
  border-radius: 10px;
  box-shadow: 1px 3px 13px rgba(0, 0, 0, 0.2);
  margin: 2.5%
}

.estateCard {
  width: 20rem;
  height: 20rem;
  background-color: #ffffff;
  border-radius: 20px;
 
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  /*  padding-left: 3rem;
  padding-right: 3rem; */
}

.popUpCard {
  display: flex;
  width: fit-content;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  align-content: center;
  justify-content: center;
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 2rem;
}

.padding {
  padding-left: 5%;
  padding-top: 2.5%;
}

.center {
  display: grid;
  align-items: center;

}