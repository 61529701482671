.form {
  padding-left: 5rem;
  padding-right: 5rem;
  padding-bottom: 5rem;
}

.formotsikko {
  padding-top: 3.5rem;
  margin-bottom: 1rem;
}
.formotsikko2 {
  margin-bottom: 1rem;
}

.row {
  display: flex;
  justify-content: space-around;
  align-items:flex-start;
  padding: 1rem;
  padding-left: 2rem;
  /* margin-top: 2rem; */
}
.inputstr {
  width: 20rem;
}

.inputint {
  width: 10;
}

.control {
  margin-bottom: 1.5rem;
}

.control label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.control input,
.control textarea {
  display: block;
  font: inherit;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 0.25rem;
  width: 100%;
}

.actions {
  margin-top: 2rem;
}

.spacebetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actionsbutton {
  font: inherit;
  cursor: pointer;
  background-color: #005c7b;
  color: white;
  padding: 0.5rem 1.5rem;
  border: 1px solid #005c7b;
  border-radius: 4px;
  font-weight: bold;
  margin: 0;
}

.actions button:hover,
.actions button:active {
  background-color: #a50e48;
  border-color: #a50e48;
}

.local table {
  width: 100%; 
  -moz-border-radius: 5px !important;
  border-radius: 20px;
  border-collapse: collapse !important;
  border: none !important;
}
.local th,
.local td {
  border: 1px solid #ddd;
  text-align: left;
  padding: 6px;
  font-size: 16px;
}

.local Link 
{
  border: 1rem  solid #3e3d3e;
  text-align: left;
  padding: 6px;
  font-size: 16px;
} 

.local th {
  background-color: /* rgb(117, 201, 250) */ /* transparent */ #cfd3d1 ;
  white-space: nowrap;
  overflow-x: auto;
}

.local td {
  background-color: /* rgb(204, 234, 253) */ /* transparent */ #f1f1f1;
}
.local td:hover {
  background-color: /* #ddd  */ rgb(255, 239, 218) !important
}