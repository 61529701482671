.actionsbutton {
  font: inherit;
  cursor: pointer;
  background-color: #005c7b;
  color: white;
  padding: 0.5rem 1.5rem;
  border: 1px solid #005c7b;
  border-radius: 4px;
  font-weight: bold;
  margin: 0;
  margin-top: 1.5%;
}

.actionsbutton:hover,
.actionsbutton:active {
  background-color: #a50e48;
  border-color: #a50e48;
}

.appcontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  
}


.local table {
  width: 100%; 
  -moz-border-radius: 5px !important;
  border-radius: 20px;
  border-collapse: collapse !important;
  border: none !important;
}
.local th,
.local td {
  border: 0.5px solid /* rgb(0, 0, 0) */ grey;
  text-align: left;
  padding: 6px;
  font-size: 16px;
}

.local Link 
{
  border: 1rem  solid #3e3d3e;
  text-align: left;
  padding: 6px;
  font-size: 16px;
} 

.local table {
  width: 100%; 
  -moz-border-radius: 5px !important;
  border-radius: 20px;
  border-collapse: collapse !important;
  border: none !important;
}
.local th,
.local td {
  border: 1px solid #ddd;
  text-align: left;
  padding: 6px;
  font-size: 16px;
}

.local Link 
{
  border: 1rem  solid #3e3d3e;
  text-align: left;
  padding: 6px;
  font-size: 16px;
} 

.local th {
  background-color: /* rgb(117, 201, 250) */ /* transparent */ #cfd3d1 ;
}

.local td {
  background-color: /* rgb(204, 234, 253) */ /* transparent */ #f1f1f1;
}
.local td:hover {
  background-color: /* #ddd  */ rgb(255, 239, 218) !important
}
input {
  text-align: left;
  align-items: center;
  font-size: 16px;
  width: 100%;
}
input:focus{
  outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.row {
display: flex;
}


.sticky {
position: sticky;
top: 0%
}
