.center {
  display: grid;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.button {
  font: inherit;
  cursor: pointer;
  background-color: #005c7b;
  color: white;
  padding: 0.5rem 1.5rem;
  border: 1px solid #005c7b;
  border-radius: 4px;
  font-weight: bold;
}
