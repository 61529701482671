.footer {
  background-color: #afb2b1;
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 6rem;
  bottom: 0;
  position: relative;
}

.content {
  display: flex;
  width: 30%;
  justify-content: center;
  align-items: center;
  color: white;
}