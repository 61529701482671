.center {
  display: grid;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.button {
  font: inherit;
  cursor: pointer;
  background-color: #005c7b;
  color: white;
  padding: 0.5rem 1.5rem;
  border: 1px solid #005c7b;
  border-radius: 4px;
  font-weight: bold;
  margin: 0;
}

.button:hover,
.button:active {
  background-color: #a50e48;
  border-color: #a50e48;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.showPassButton {
  margin-top: 1.5%; 
  background-color: transparent; 
  border: none; 
  padding: 0;
  opacity: 0.7;
}

.showPassButton:hover {
  opacity: 1;
}

.inputWithButton {
  position: relative;
}

.inputWithButton input{
    padding-right: 60px;
}

.inputWithButton button {
  position: absolute;
  right: 10px;
}