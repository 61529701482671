.header {
  background-color: #afb2b1;
  height: 6rem;
  width: 100%;
  display: flex;
  align-items: center;
}
.headerorder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.row {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: space-around;
}

.headerorder2 {
  display: flex;
  width: 40%;
  justify-content: space-around;
}

.link {
  font-weight: "normal";
  text-decoration: none;
  color: white;
  opacity: 0.8;
}
.link:focus {
  opacity: 1;
}

.link:hover {
  opacity: 1;
  color: white;
  cursor: pointer;
}

.dropdown {
  list-style-type: none;
  position: absolute;
  background: #424242;
  padding: 1%;
}

@media (max-width: 1650px) {
  .row {
    display: flex;
    width: 60%;
    align-items: center;
    justify-content: space-around;
  }
}