.showPassButton {
  margin-top: 1.5%;
  background-color: transparent;
  border: none;
  padding: 0;
  opacity: 0.7;
}

.showPassButton:hover {
  opacity: 1;
}

.inputWithButton {
  position: relative;
}

.inputWithButton input {
  padding-right: 60px;
}

.inputWithButton button {
  position: absolute;
  right: 10px;
}
