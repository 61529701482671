#users {
  border-collapse: collapse;
  width: 100%;
}

#users td,
#users th {
  border: 1px solid #ddd;
  padding: 8px;
}

/* #users tr:hover {
  background-color: rgb(170, 170, 170);
} */

#users th {
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: left;
  background-color: grey;
  color: white;
}

button {
  font-family: 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #000000;
  cursor: pointer;
  padding: 0px 30px;
  display: inline-block;
  margin: 10px 15px;
  text-transform: uppercase;
  line-height: 2em;
  letter-spacing: 1.5px;
  font-size: 1em;
  outline: none;
  /* position: relative; */
  font-size: 14px;
  border: 3px solid #cfcece;
  background-color: #ffffff;
  border-radius: 15px 15px 15px 15px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

button:hover {
  border-color: #2980b9;
}

button:disabled {
  color: grey;
}

button:disabled:hover {
  border-color: rgb(138, 138, 138);
}

input[type=text], select, input[type=password] {
  width: 100%;
  /* padding: 12px 20px;
  margin: 8px 0; */
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type=submit] {
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: #45a049;
}

.space {
  width: 4px;
  height: auto;
  display: inline-block;
}