.link {
    font-weight: "normal";
    text-decoration: none;
    color: white;
  }
  .link:focus {
    color: grey;
  }
  
  .link:hover {
    color: grey;
    cursor: pointer;
  }
  
  .dropdown {
    list-style-type: none;
    position: absolute;
    background: #424242;
    padding: 0.5%;
  } 

  .li {
    text-decoration: none; 
    list-style: none; 
    color: #0d96f8; 
    cursor: pointer;
  }

  .liRed {
    text-decoration: none; 
    list-style: none; 
    color: #f80d0d; 
    cursor: pointer;
  }

  .changeColor td {
    background-color: /* rgb(204, 234, 253) */ /* transparent */ #e5e5e5;
  }