button {
  font: inherit;
  cursor: progress;
  background-color: #005c7b;
  color: white;
  padding: 0.5rem 1.5rem;
  border: 1px solid #005c7b;
  border-radius: 10px;
  font-weight: bold;
}

.space {
  width: 4px;
  height: auto;
  display: inline-block;
}

.inputtext /*, select */ {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.inputsubmit {
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.inputsubmit:hover {
  background-color: #45a049;
}

.inputdelete {
  width: 100%;
  background-color: #e92121;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.inputdelete:hover {
  background-color: #800808;
}

.editdiv {
  border-radius: 5px;
  padding: 50px;
  margin: 2%;
}

.example-warper {
  width: 100%;
  padding: 20px 5%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  border: 1px #cfcece dashed;
}

.button {
  font-family: "Roboto", Arial, sans-serif;
  color: #000000;
  cursor: pointer;
  padding: 0px 30px;
  display: inline-block;
  margin: 10px 15px;
  text-transform: uppercase;
  line-height: 2em;
  letter-spacing: 1.5px;
  font-size: 1em;
  outline: none;
  position: relative;
  font-size: 14px;
  border: 3px solid #cfcece;
  background-color: #ffffff;
  border-radius: 15px 15px 15px 15px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.button:hover,
.button.hover {
  border-color: #2980b9;
}
/*
card 
*/
.card {
  font-size: 12px;
}
.card > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 14px;
  text-align: center;
}

.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  margin: auto;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.example-warper-start {
  width: 100%;
  padding: 20px 5%;
  display: flex;
  flex-wrap: wrap;
  border: 1px #cfcece dashed;
}

.menu {
  width: 200px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
}
.menu-item {
  cursor: pointer;
  padding: 5px;
  height: 28px;
  border-top: 1px solid rgb(187, 187, 187);
}
.menu-item:hover {
  color: #2980b9;
}
