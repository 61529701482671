.column {
  display: flex;  
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;
}

.demo {
  background-color: white;
  margin-top: 0%;
  margin-right: 0%;
  /* padding-left: 3rem; */
  border-radius: 10px;
  box-shadow: 1px 3px 13px rgba(0, 0, 0, 0.2);
  margin-left: 2.5%;
  margin-right: 2.5%;
}

.center {
  display: grid;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}