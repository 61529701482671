.padding {
  margin-left: 10%;
  margin-right: 10%;
  padding-top: 5%;
}

.grid {
  display: grid;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.local table { 
  -moz-border-radius: 5px !important;
  border-radius: 20px;
  border-collapse: collapse !important;
  border: hidden !important;

}
.local th,
.local td {
  border-bottom: 1px solid grey; 
  padding-inline: 8px;
  text-align: left;
  font-size: 18px;
}

.local th {
  font-weight: bold;
}

.local td {
  font-size: 16px;
}
