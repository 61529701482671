.center {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}

.content {
  display: grid;
  /* place-items: center;
  text-align: center; */
  min-width: 500px;
  padding: 10%;
}


.items {
  overflow-y: /* hidden */ scroll;
  scrollbar-color: transparent;
  height: 250px;
  width: 100%;
}

.row {
  display: flex;
  width: 100%;
  height: 2rem;
  justify-content: space-between;
  align-items: center;
} 