.local table {
  -moz-border-radius: 5px !important;
  border-radius: 20px;
  border-collapse: collapse !important;
  border: hidden !important;
}
.local th,
.local td {
  border-bottom: 1px solid grey; 
  padding-inline: 8px;
  text-align: left;
  font-size: 16px;
}

.local th {
  font-weight: bold;
}

.local td {
  font-size: 14px;
}