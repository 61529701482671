.center {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
}

.button {
  background-color: transparent;  
  border-radius: 2;
  border: none;
  padding: 1%;
  margin-bottom: 3%;
  font-size: 14px;
}

.local table {
  display: table;
  table-layout: fixed;
  max-height: 100%;
  -moz-border-radius: 5px !important;
  border-radius: 20px;
  border-collapse: collapse !important;
  border: none !important;
}

.local th,
.local td {
  border: 1px solid #ddd;
  text-align: left;
  padding: 6px;
  font-size: 16px;
}

.local Link 
{
  border: 1rem  solid #3e3d3e;
  text-align: left;
  padding: 6px;
  font-size: 16px;
} 

.local th {
  background-color: /* rgb(117, 201, 250) */ /* transparent */ #cfd3d1;
}

.local td {
  background-color: /* rgb(204, 234, 253) */ /* transparent */ #f1f1f1;
}
.local td:hover {
  background-color: /* #ddd  */ rgb(255, 239, 218) !important
}